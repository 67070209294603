$('span.ajax-loader').hide();


// $('.c-form-inputItem-radio .wpcf7-list-item').on('click', function(){
// 	$('.c-form-inputItem-radio .wpcf7-list-item').removeClass('is-active');
// 		$(this).addClass('is-active');
// });

// $('.wpcf7-confirm').on('click', function(){
// 	// $("body").hide();
//
// 	setTimeout(function() {
// 		$("body").show();
// 		if($(".wpcf7c-conf").length) {
// 			console.log("confirm")
// 			$('body').addClass('wp-contactform7-confirm-page');
// 		}
// 	}, 800);
// });
// $('.wpcf7-back').on('click', function(){
// 	// $("body").hide();
// 	setTimeout(function() {
// 		$("body").show();
// 		$('body').removeClass('wp-contactform7-confirm-page');
// 	}, 300);
// });
// $('.wpcf7-submit').on('click', function(){
// 	location = "/contact/thanks/";
// });
// $('.wpcf7c-btn-back').on('click', function(){
// 	$("#input-label").show();
// 	$("#confirm-label").hide();
// 	$("input[name^=radio-]:not(:checked)").closest('span').show();
// 	$("small").show();
// 	$("[name=email_confirm]").show();
// 	$("section.form").attr('id', "INPUT");
// });



document.addEventListener('wpcf7submit', function (event) {
	switch (event.detail.status) {
		case 'wpcf7c_confirmed':
			$('body').addClass('wp-contactform7-confirm-page');
			$("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').hide();
			// $("input[type=checkbox]:not(:checked)").closest('span').hide();
			// $(".txt_comment").hide();
			$("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').hide();
			// $("div.c-form").addClass("is-confirm");
			// $('li.c-form__stepItem').eq(0).removeClass('is-current');
			// $('li.c-form__stepItem').eq(1).addClass('is-current');
			// $('li.c-form__stepItem').eq(0).addClass('is-complete');
			break;
	}
}, false);

document.addEventListener('wpcf7mailsent', function (event) {
	if (document.querySelector('.is-entry')) {
		location = '/thanks/'; 
	} else if (document.querySelector('.is-contact')) {
		location = '/thanks/'; 
	} else {
		location = '/thanks/'; 
	}
}, false);
$('.wpcf7c-btn-back').on('click', function () {
	$('body').removeClass('wp-contactform7-confirm-page');
	$("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').show();
	$("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').show();
});

