// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_hero_pre = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onLeave',
  duration: 800,
  offset: 0
})
// .addIndicators()
.addTo(controller);

scene_hero_pre.on('enter', () => {
  //triggerElementを過ぎたとき
  $('.c-scrollPageTop').removeClass('-display');
});
scene_hero_pre.on('leave', () => {
  //triggerElementを抜けたとき
  $('.c-scrollPageTop').addClass('-display');
});

var scene_hero_next = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onLeave',
  duration: 830,
  offset: 0
})
// .addIndicators()
.addTo(controller);

scene_hero_next.on('enter', () => {
  //triggerElementを過ぎたとき
  $('.c-scrollPageTop').removeClass('-opacity');
});
scene_hero_next.on('leave', () => {
  //triggerElementを抜けたとき
  $('.c-scrollPageTop').addClass('-opacity');
});
